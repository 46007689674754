import React from "react";
import honeypotz1 from "./assets/images/honeypotz1.png";

const HoneyPotz = () => {
  return (
    <div className="d-flex justify-content-end mb-3">
      <img src={honeypotz1} alt="" className="honeypotz1" width={200} />
    </div>
  );
};

export default HoneyPotz;
